import React from 'react'
import { Link, Element } from 'react-scroll'
import Helmet from 'react-helmet'

import { BrandLayout } from '../components/Layouts'
import Swatch from '../components/Swatch'
import TypeSpecimen from '../components/TypeSpecimen'

import nxslogo from '../images/nxs-logo.svg'
import WebfontBuilder from '../components/WebfontBuilder'

const ScrollLink = ({ to, name }) => (
  <Link
    to={to}
    spy={true}
    smooth={true}
    hashSpy={true}
    isDynamic={true}
    activeClass="text-black font-bold"
    className="text-xl cursor-pointer mx-3 lg:mx-0 lg:my-1 lg:block"
  >
    {name}
  </Link>
)

const Section = ({ id, children, title }) => (
  <Element
    id={id}
    className="px-6 py-8 lg:py-16 border-grey-300 border-t first:border-t-0"
  >
    <div className="section-content max-w-4xl mx-auto">
      <h2 className="text-4xl mt-0">{title}</h2>
      {children}
    </div>
  </Element>
)

const sections = [
  {
    name: 'Logos',
    id: 'logos',
  },
  {
    name: 'Colors',
    id: 'colors',
    sourceKey: 'palettes',
  },
  {
    name: 'Typography',
    id: 'typography',
    sourceKey: 'fonts',
  },
]

export default ({ data }) => {
  const { brand } = data

  let fileExt = brand.usePNG ? 'png' : 'svg'

  return (
    <BrandLayout>
      <Helmet
        title={`${brand.name} Style Guide`}
        meta={[
          {
            name: `${brand.name} Style Guide`,
            content: `Logo and brand standards for ${brand.name}, prepared by North X South`,
          },
        ]}
      />
      <div className="lg:flex">
        <div className="shadow-lg lg:w-1/4 relative z-10 sticky top-0 bg-white">
          <div className="lg:sticky top-0 p-4 text-center">
            <div
              className="h-3 lg:h-16 -mx-4 -mt-4 mb-2 lg:-mb-12"
              style={{ backgroundColor: brand.color }}
            />
            <a
              href={brand.url}
              className="hidden lg:inline-block mb-6 rounded-full overflow-hidden shadow-xl hover:shadow-2xl transition"
              rel="noopener noreferrer"
            >
              <img
                className="w-32 bg-white rounded-full"
                src={`/${brand.slug}/${brand.icon}.${fileExt}`}
                alt={`${brand.name} Logo`}
              />
            </a>
            <h1 className="mb-2 lg:mb-4 text-2xl xl:text-3xl text-center lg:text-left">
              {brand.name} Style Guide
            </h1>
            <nav className="text-grey-600 scrolling-touch md:scrolling-auto overflow-hidden text-center lg:text-left">
              {sections.map(sec => {
                if (!brand[sec.sourceKey || sec.id]) return
                return <ScrollLink key={sec.id} to={sec.id} name={sec.name} />
              })}
            </nav>
          </div>
        </div>

        <main className="w-full">
          {brand.logos && (
            <Section id="logos" title="Logos">
              {brand.logos.map(logo => (
                <div key={logo.name}>
                  <h3 className="mt-5 mb-2 font-bold text-2xl">{logo.name}</h3>
                  {logo.desc && <p className="italic">{logo.desc}</p>}
                  <div className="row">
                    {logo.images.map(image => {
                      let size = logo.images.length

                      let colSpan = 2
                      if (size % 4 === 0) {
                        colSpan = 4
                      } else if (size % 3 === 0) {
                        colSpan = 3
                      }
                      let colClasses = `col my-3 w-1/2 md:w-1/${colSpan}`

                      return (
                        <div className={colClasses} key={image.name}>
                          <div className="mb-3 shadow checkerboard rounded-lg relative">
                            <img
                              className="p-5 object-contain w-full h-32 lg:h-48"
                              src={`/${brand.slug}/${image.name}.${fileExt}`}
                              alt={`${brand.name} ${logo.name} Format Logo`}
                            />
                            <div className="sm:opacity-0 sm:hover:opacity-100 sm:absolute top-0 left-0 bg-grey-100 w-full h-full p-3 flex flex-wrap sm:flex-col justify-center items-center transition">
                              <p className="font-bold sm:text-2xl leading-none hidden md:block">
                                Download:
                              </p>
                              <div className="text-sm sm:text-base sm:mt-3">
                                {image.formats.map(file => (
                                  <a
                                    key={file}
                                    href={`/${brand.slug}/${image.name}.${file}`}
                                    className="mx-1 mt-1 lg:mt-0 inline-block bg-grey-800 hover:bg-grey-600 text-white px-2 py-1 rounded uppercase tracking-wide"
                                    download
                                  >
                                    {file}
                                  </a>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </Section>
          )}

          {brand.palettes && (
            <Section id="colors" title="Colors">
              {brand.palettes.map(palette => (
                <div key={palette.name}>
                  <h3 className="mt-5 mb-2 font-bold text-2xl">
                    {palette.name}
                  </h3>
                  {palette.desc && <p className="italic">{palette.desc}</p>}
                  <div className="row">
                    {palette.colors.map(color => (
                      <div key={color.name} className="col my-3 w-1/2 md:w-1/3">
                        <Swatch name={color.name} color={color.hex} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Section>
          )}

          {brand.fonts && (
            <Section id="typography" title="Typography">
              <WebfontBuilder fonts={brand.fonts} />
              <div className="row">
                {brand.fonts.map(font => (
                  <div
                    key={font.name}
                    className="col py-3 w-full md:w-1/2 type-specimen-wrapper"
                  >
                    <h3 className="mt-5 mb-2 font-bold text-2xl">
                      {font.name}
                    </h3>
                    <TypeSpecimen
                      name={font.name}
                      classification={font.classification}
                    />
                    {font.desc && <p className="mt-3 italic">{font.desc}</p>}
                  </div>
                ))}
              </div>
            </Section>
          )}
          <footer className="text-center px-4 py-5 bg-grey-100">
            <p className="md:flex justify-center items-center leading-tight mb-2">
              Style Guide prepared by{' '}
              <a href="https://www.northxsouth.co">
                <img
                  src={nxslogo}
                  className="w-48 h-auto block mt-2 mx-auto md:h-6 md:w-auto md:inline-block md:ml-2 md:mt-0"
                  alt="North X South logo"
                />
              </a>
            </p>
            <p>
              For questions or assistance, contact{' '}
              <a
                href="hello@northxsouth.co"
                className="no-underline text-black font-semibold hover:text-grey-600"
              >
                hello@northxsouth.co
              </a>
            </p>
          </footer>
        </main>
      </div>
    </BrandLayout>
  )
}

export const query = graphql`
  query FetchBrand($id: String) {
    brand: brandsJson(slug: { eq: $id }) {
      id
      name
      slug
      url
      icon
      usePNG
      color
      logos {
        name
        desc
        images {
          name
          formats
        }
      }
      palettes {
        name
        desc
        colors {
          name
          hex
        }
      }
      fonts {
        name
        desc
        source
        id
        weight
        classification
      }
    }
  }
`
