import React from 'react'

const TypeSpecimen = ({ name, classification }) => {
  const stack = `'${name}', ${classification}`
  return (
    <div
      className="p-4 rounded bg-grey-100 shadow overflow-hidden"
      style={{ fontFamily: stack }}
    >
      <p className="text-4xl leading-tight whitespace-no-wrap overflow-hidden mb-1">
        A wizard’s job is to vex chumps quickly in fog
      </p>
      <p className="text-xl leading-tight whitespace-no-wrap overflow-hidden text-grey-900 mb-1">
        Amazingly few discotheques provide jukeboxes
      </p>
      <p className="whitespace-no-wrap overflow-hidden text-grey-800 uppercase tracking-wide mb-1">
        Jackdaws love my big sphinx of quartz
      </p>
      <p className="text-sm line-clamp-3 text-grey-700">
        By Jove, my quick study of lexicography won a prize! Painful zombies
        quickly watch a jinxed graveyard. Grumpy wizards make a toxic brew for
        the jovial queen. A quick movement of the enemy will jeopardize six
        gunboats. Jackie will budget for the most expensive zoology equipment.
        Jim quickly realized that the beautiful gowns are expensive, then a cop
        quizzed Mick Jagger’s ex-wives briefly.
      </p>
    </div>
  )
}

export default TypeSpecimen
