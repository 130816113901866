import React from 'react'
import Helmet from 'react-helmet'

const WebfontBuilder = ({ fonts }) => {
  let webfonts = fonts

  let nxsFontFaces = webfonts
    .filter(webfonts => webfonts.source === 'self')
    .map(
      font => `
        @font-face {
          font-family: '${font.name}';
          font-style: normal;
          font-weight: ${font.weight};
          font-display: swap;
          src: local("${font.name}"),
            url(https://fonts.northxsouth.co/${font.id}/${font.weight}.woff) format("woff"),
            url(https://fonts.northxsouth.co/${font.id}/${font.weight}.woff2) format("woff2");
          }
      `
    )
    .join('')

  let googleImport = webfonts
    .filter(webfonts => webfonts.source === 'google')
    .map(font => `${font.name}:${font.weight}`)
    .join('|')

  return (
    <Helmet>
      {nxsFontFaces && <style type="text/css">{nxsFontFaces}</style>}
      {googleImport && (
        <link
          href={`https://fonts.googleapis.com/css?family=${googleImport}&display=swap`}
          rel="stylesheet"
        />
      )}
    </Helmet>
  )
}

export default WebfontBuilder
