import React from 'react'
import convert from 'color-convert'
import yiq from 'yiq'

const ColorValueRow = ({ label, color }) => {
  return (
    <div className="flex -mx-3 my-1 justify-between overflow-hidden">
      <div className="w-auto px-3">{label}:</div>
      <div className="w-auto px-3">{color}</div>
    </div>
  )
}

const Swatch = ({ color, name, ...rest }) => {
  return (
    <div className="w-100 shadow rounded-lg " {...rest}>
      <div
        className="swatch rounded-t-lg h-32 p-3 flex items-end"
        style={{ backgroundColor: color }}
      >
        <h4
          className={`m-0 text-2xl font-bold leading-tight break-words ${yiq(
            color,
            {
              white: 'text-white',
              black: 'text-black',
            }
          )}`}
        >
          {name}
        </h4>
      </div>
      <div className="rounded-b-lg px-2 py-1 border-grey-100 border-t text-grey-700 text-sm">
        <ColorValueRow label="Hex" color={color} />
        <ColorValueRow
          label="RGB"
          color={`rgb(${convert.hex.rgb(color).join(',')})`}
        />
        <ColorValueRow label="CMYK" color={convert.hex.cmyk(color).join('/')} />
      </div>
    </div>
  )
}

export default Swatch
